import { useState } from "react";
import Section from "../section";
import Payment from "../payment";
import { SectionType } from "../../context/@types.context";

const Subscription = ({ dispatch }: SectionType) => {
  const [isPayment, setIsPayment] = useState(false);

  const payment = () => {
    setIsPayment(!isPayment);
  };

  return (
    <Section dispatch={dispatch} title="Abonament">
      <div className="subscription">
        <button
          className="subscription__button subscription__button--margin"
          onClick={payment}
        >
          Metoda płatności
        </button>
      </div>
      <Payment isPayment={isPayment} dispatch={payment} />
      <div className="subscription">
        <p className="subscription__text">
          Oferta Abonamentowa CRM dla Firmy Żeglugowej
        </p>
        <p className="subscription__text">Szanowni Państwo,</p>
        <p className="subscription__text">
          Z przyjemnością przedstawiamy naszą ofertę abonamentową na dostęp do
          systemu CRM, który został stworzony z myślą o firmach żeglugowych.
          Nasze rozwiązanie wspiera zarządzanie, organizację oraz optymalizację
          procesów produkcji oraz eksploatacji jednostek pływających. Przekłada
          się to na lepszą efektywność w firmie.
        </p>
        <p className="subscription__text">Warunki Umowy</p>
        <p className="subscription__text">
          <span className="subscription__weight">
            Dobrowolność i Elastyczność:
          </span>{" "}
          Umowa abonamentowa jest umowna i całkowicie dobrowolna. Klient ma
          pełną swobodę w wyborze okresu abonamentowego oraz rodzaju usług, z
          których chce korzystać. Możliwe jest także zakończenie współpracy w
          dowolnym momencie, zgodnie z warunkami opisanymi w umowie.
        </p>
        <p className="subscription__text">
          <span className="subscription__weight">
            Okres Rozliczeniowy:
          </span>{" "}
          Abonament jest rozliczany w cyklu miesięcznym lub rocznym, w
          zależności od preferencji Klienta. Istnieje możliwość dostosowania
          warunków abonamentu do indywidualnych potrzeb, co pozwala na
          maksymalizację korzyści płynących z korzystania z systemu CRM.
        </p>
        <p className="subscription__text">
          <span className="subscription__weight">
            Zakres Usług:
          </span>{" "}
          Nasze rozwiązanie CRM obejmuje pełen pakiet przydatnych funkcji.
          Wszystkie aktualizacje i wsparcie techniczne są zawarte w cenie
          abonamentu.
        </p>

        <p className="subscription__text">
          <span className="subscription__weight">
            Bezpieczeństwo i Poufność:
          </span>{" "}
          Gwarantujemy najwyższy poziom ochrony danych, a wszelkie informacje
          przekazywane w ramach współpracy są poufne i wykorzystywane wyłącznie
          w celach związanych z realizacją usług.
        </p>
        <p className="subscription__text">
          <span className="subscription__weight">
            Brak Długoterminowych Zobowiązań:
          </span>{" "}
          Nasza umowa nie wymaga długoterminowych zobowiązań. Klient może w
          dowolnym momencie zmienić zakres usług, zakończyć współpracę lub
          dostosować warunki abonamentu, co daje pełną elastyczność i kontrolę
          nad wydatkami. Jesteśmy przekonani, że nasze rozwiązanie CRM
          przyniesie realne korzyści Państwa firmie, wspierając codzienną
          działalność oraz relacje z pracownikami. W razie pytań lub
          wątpliwości, zapraszamy do kontaktu. Chętnie omówimy szczegóły oferty
          i pomożemy w dostosowaniu systemu do Państwa potrzeb.
        </p>
      </div>
    </Section>
  );
};

export default Subscription;
